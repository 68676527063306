export const addFavicon = url => {
  const favicon = document.createElement("link");
  favicon.rel = "icon";
  favicon.href = url;
  document.head.appendChild(favicon);
};

export const injectCSSTop = css => {
  const style = document.createElement("style");
  style.type = "text/css";
  style.appendChild(document.createTextNode(css));
  document.head.insertBefore(style, document.head.firstChild);
};

export const setBrowserTitle = title => {
  document.title = title;
};
