import { arrayOf, oneOf, shape, string } from "prop-types";
import { addFavicon, injectCSSTop, setBrowserTitle } from "./utils/dom";
import { fetchJSON, fetchText } from "./utils/fetch";

/**
 * Config type definitions
 */
const servicePropType = shape({
  url: string.isRequired,
  basePath: string.isRequired
});
export const configPropTypes = {
  agentSupportUrl: string.isRequired,
  environmentId: oneOf(["dev-int", "stage", "prod"]).isRequired,
  customerId: oneOf(["sagesure", "fednat"]).isRequired,
  loginRequiredRole: string,
  defaultModule: arrayOf(string).isRequired,
  googleMapsKey: string,
  launchDarklyKey: string.isRequired,
  mixpanelToken: string,
  pollyJSServer: string,
  statusApiUrl: string,
  statusPageUrl: string,
  zendeskTicketsUrl: string,
  appTokenPolicyCentral: string,
  serviceTokenIxAdmin: string,
  profilesWorkflowsUrl: string,
  awsFnolForms: string,

  // Retrieved async from file, see: `/customers/${CUSTOMER_ID}/customer.json`
  customer: shape({
    displayName: string,
    url: string,
    footerSupportUrl: string,
    usefulLinks: arrayOf(
      shape({
        label: string.isRequired,
        href: string.isRequired
      })
    ).isRequired
  }).isRequired,

  rulesetTags: arrayOf(string).isRequired,

  services: shape({
    url: string,
    password: servicePropType,
    ixlibrary: servicePropType,
    ixprofiler: servicePropType,
    ixvocab: servicePropType,
    pxcentral: servicePropType,
    pxserver: servicePropType,
    mxserver: servicePropType,
    quoting: servicePropType,
    batches: servicePropType,
    ixdirectory: servicePropType,
    esign: servicePropType
  }).isRequired,

  graphql: shape({
    configuration: string,
    renewals: string,
    policy: string,
    policies: string
  }).isRequired,

  apps: shape({
    policycentral: shape({
      url: string,
      basePathPolicy: string
    })
  })
};

/**
 * Config - initially defined here, but then modified by `loadRuntimeConfig()`
 * at runtime right before the app mounts.
 *
 * `undefined` represents those values which get set at runtime
 */
export const createConfig = ({
  runtimeConfig: {
    AGENT_SUPPORT_URL,
    ENVIRONMENT_ID,
    CUSTOMER_ID,
    LOGIN_REQUIRED_ROLE,
    DEFAULT_MODULE,
    GOOGLE_MAPS_KEY,
    LAUNCH_DARKLY_KEY,
    MIXPANEL_TOKEN,
    POLLY_JS_SERVER,
    STATUS_API_URL,
    STATUS_PAGE_URL,
    GRAPHQL_URL_CONFIGURATION,
    GRAPHQL_URL_RENEWALS,
    GRAPHQL_URL_POLICY,
    GRAPHQL_URL_POLICIES,
    RULESET_TAGS,
    SERVICE_URL,
    SERVICE_URL_PASSWORD,
    SERVICE_URL_IXVOCAB,
    SERVICE_URL_IXLIBRARY,
    SERVICE_URL_IXPROFILER,
    SERVICE_URL_IXLOGIC,
    SERVICE_URL_IXRELAY,
    SERVICE_URL_RULESETS,
    SERVICE_URL_PAYMENTS,
    SERVICE_URL_PXCENTRAL,
    SERVICE_URL_PXSERVER,
    SERVICE_URL_IAC_API,
    SERVICE_TOKEN_IAC_API,
    SERVICE_URL_MXSERVER,
    SERVICE_URL_BATCHES,
    SERVICE_URL_QUOTING,
    SERVICE_URL_IXDIRECTORY,
    SERVICE_URL_ESIGN,
    SERVICE_TOKEN_IXVOCAB,
    SERVICE_TOKEN_IXPROFILER,
    SERVICE_TOKEN_IXADMIN,
    SERVICE_URL_PROFILES_WORKFLOWS,
    ZENDESK_TICKETS_URL,
    SERVICE_URL_ZENDESK,
    APP_TOKEN_POLICY_CENTRAL,
    APP_URL_POLICY_CENTRAL,
    AWS_FNOL_FORMS
  },
  customerConfig
}) => ({
  agentSupportUrl: AGENT_SUPPORT_URL,
  environmentId: ENVIRONMENT_ID,
  customerId: CUSTOMER_ID,
  loginRequiredRole: LOGIN_REQUIRED_ROLE,
  defaultModule: DEFAULT_MODULE,
  googleMapsKey: GOOGLE_MAPS_KEY,
  launchDarklyKey: LAUNCH_DARKLY_KEY,
  mixpanelToken: MIXPANEL_TOKEN,
  pollyJSServer: POLLY_JS_SERVER,
  statusApiUrl: STATUS_API_URL,
  statusPageUrl: STATUS_PAGE_URL,
  zendeskTicketsUrl: ZENDESK_TICKETS_URL,
  appTokenPolicyCentral: APP_TOKEN_POLICY_CENTRAL,
  serviceTokenIxAdmin: SERVICE_TOKEN_IXADMIN,
  profilesWorkflowsUrl: SERVICE_URL_PROFILES_WORKFLOWS,
  awsFnolForms: AWS_FNOL_FORMS,
  customer: customerConfig,
  rulesetTags: RULESET_TAGS,
  services: {
    url: SERVICE_URL,
    ixlibrary: { url: SERVICE_URL_IXLIBRARY, basePath: "api/sdo/rest/v1" },
    ixprofiler: {
      url: SERVICE_URL_IXPROFILER,
      basePath: "api/rest/v2",
      token: SERVICE_TOKEN_IXPROFILER
    },
    ixlogic: { url: SERVICE_URL_IXLOGIC, basePath: "" },
    rulesets: { url: SERVICE_URL_RULESETS, basePath: "" },
    ixvocab: {
      url: SERVICE_URL_IXVOCAB,
      basePath: "api/rest/v2",
      token: SERVICE_TOKEN_IXVOCAB
    },
    ixrelay: { url: SERVICE_URL_IXRELAY, basePath: "api/rest/v1" },
    payments: { url: SERVICE_URL_PAYMENTS, basePath: "" },
    pxcentral: { url: SERVICE_URL_PXCENTRAL, basePath: "api/rest/v1" },
    pxserver: { url: SERVICE_URL_PXSERVER, basePath: "" },
    iacapi: {
      url: SERVICE_URL_IAC_API,
      basePath: "api/rest/v2",
      token: SERVICE_TOKEN_IAC_API
    },
    cxserver: { url: "", basePath: "cxserver/api/rest/v3" },
    mxserver: { url: SERVICE_URL_MXSERVER, basePath: "api/rest/v1" },
    quoting: { url: SERVICE_URL_QUOTING, basePath: "api/rest/v1" },
    batches: { url: SERVICE_URL_BATCHES, basePath: "api/rest/v1/batch" },
    password: { url: SERVICE_URL_PASSWORD, basePath: "api/rest/v1" },
    ixdirectory: { url: SERVICE_URL_IXDIRECTORY, basePath: "api/rest/v2" },
    esign: { url: SERVICE_URL_ESIGN, basePath: "api/v3" },
    zendesk: { url: SERVICE_URL_ZENDESK, basePath: "" }
  },
  graphql: {
    configuration: GRAPHQL_URL_CONFIGURATION,
    renewals: GRAPHQL_URL_RENEWALS,
    policy: GRAPHQL_URL_POLICY,
    policies: GRAPHQL_URL_POLICIES
  },
  apps: {
    policycentral: { url: APP_URL_POLICY_CENTRAL, basePathPolicy: "policy" }
  }
});

export function loadRuntimeConfig() {
  // We append `v=X.X.X` to allow version cache busting on these static assets
  return fetchJSON(`/runtime-config.json?v=${__VERSION__}`)
    .then(runtimeConfig => {
      // Possibly override the customer if it's in local storage
      // (dev/QA purposes only)
      const customerIdOverride = window.localStorage.getItem("CUSTOMER_ID");
      return customerIdOverride
        ? {
            ...runtimeConfig,
            CUSTOMER_ID: customerIdOverride
          }
        : runtimeConfig;
    })
    .then(runtimeConfig => {
      const { CUSTOMER_ID } = runtimeConfig;
      // DOM manipulation
      setBrowserTitle(runtimeConfig.BROWSER_TITLE);
      // Create <link> for favicon based on customer
      addFavicon(`/customers/${CUSTOMER_ID}/favicon.ico?v=${__VERSION__}`);
      // Append CSS at the beginning of <head> (as there could be other <style>
      // elements in <head> and we want this to be the "base" CSS)
      fetchText(`/customers/${CUSTOMER_ID}/styles.css?v=${__VERSION__}`).then(
        injectCSSTop
      );

      return Promise.all([
        runtimeConfig,
        // Fetch customer-related assets (stylesheet, customer config)
        fetchJSON(`/customers/${CUSTOMER_ID}/customer.json?v=${__VERSION__}`)
      ]);
    })
    .then(([runtimeConfig, customerConfig]) =>
      createConfig({ runtimeConfig, customerConfig })
    );
}
