/* eslint-disable no-console */
import React, { Suspense } from "react";
import { shape, objectOf, bool, oneOfType, object } from "prop-types";
import { Route, Switch } from "react-router-dom";
import {
  LoadingPage,
  QANDP_AGENT_PORTAL_NOTICES
} from "@package/ipcmgr-toolkit";

import { configPropTypes } from "../config";
import { userPropTypes, hasModuleAccess } from "../utils/user";
import routes from "../routes";
import ModuleBoundary from "./ModuleBoundary";
import UnknownRoute from "./UnknownRoute";
import ResetPassword from "./ResetPassword";
import WhatsNew from "./WhatsNew";
import Notices from "./Notices";

const propTypes = {
  config: shape(configPropTypes).isRequired,
  flags: objectOf(oneOfType([bool, object])).isRequired,
  user: shape(userPropTypes).isRequired
};

const Main = ({ config, flags, user }) =>
  flags.isReady ? (
    <Suspense
      fallback={
        <main>
          <LoadingPage />
        </main>
      }
    >
      <Switch>
        {hasModuleAccess(user, routes, flags).map(
          ({ path, component: Module, abbr = "default", label }) => (
            <Route
              path={path}
              exact={path === "/"}
              key={path}
              render={({ location }) => (
                <main className={`clearfix mod-${abbr}`}>
                  <ModuleBoundary
                    label={label}
                    location={location}
                    config={config}
                  >
                    <Module
                      basename={path}
                      config={config}
                      flags={flags}
                      user={user}
                    />
                  </ModuleBoundary>
                </main>
              )}
            />
          )
        )}
        <Route
          exact
          path="/reset-password"
          render={history => (
            <main className="clearfix">
              <ResetPassword config={config} user={user} history={history} />
            </main>
          )}
        />
        <Route
          path="/whats-new"
          render={() => (
            <main className="clearfix">
              <WhatsNew config={config} user={user} flags={flags} />
            </main>
          )}
        />
        {flags[QANDP_AGENT_PORTAL_NOTICES] && (
          <Route
            path="/notices"
            render={() => (
              <main className="clearfix">
                <Notices config={config} user={user} flags={flags} />
              </main>
            )}
          />
        )}
        <Route
          render={() => (
            <main className="clearfix">
              <UnknownRoute />
            </main>
          )}
        />
      </Switch>
    </Suspense>
  ) : (
    <main>
      <LoadingPage />
    </main>
  );

Main.propTypes = propTypes;

export default Main;
