import { QueryClient } from "react-query";
import { get } from "lodash";

const createReactQueryClient = promises => {
  const notFound = key => () => {
    throw new Error(`The promise for "${key}" was not found.`);
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: ({ queryKey: [key, ...params] }) =>
          get(promises, key, notFound(key))(...params),
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  });

  return queryClient;
};

export default createReactQueryClient;
