import { Auth } from "@icg360/auth-js";
import { arrayOf, string } from "prop-types";
import { hasRole } from "@package/ipcmgr-toolkit";

export const userPropTypes = {
  id: string.isRequired,
  roles: arrayOf(string).isRequired
};

export const login = ({ username, password, servicesUrl }) => {
  const authClient = new Auth({
    servicesUrl
  });
  return authClient
    .login(username, password)
    .then(res => res.identity)
    .catch(({ message }) => ({ status: "error", message }));
};

export const logout = servicesUrl => {
  const authClient = new Auth({
    servicesUrl
  });
  return authClient.logout();
};

export const checkSession = servicesUrl => {
  const authClient = new Auth({
    servicesUrl
  });
  return authClient
    .identify()
    .then(res => res.identity)
    .catch(() => null);
};

export const hasModuleAccess = (user, module, flags) =>
  module.filter(({ flag, roles }) => {
    if (!flag || flags[flag]) {
      return true;
    }
    if (roles && roles.length !== 0) {
      return hasRole(user.roles, ...roles);
    }
    return false;
  });

export const forgotPassword = (username, config) => {
  const requestData = {
    resetCallback: `reset-token=${
      window.location.origin
    }/reset-password?reset-token=`
  };
  const url = `${config.services.password.url}/${
    config.services.password.basePath
  }/password/${username}/resetrequest`;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(requestData),
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    }
  })
    .then(res => res.text())
    .then(data => (data ? JSON.parse(data) : {}))
    .catch(error => error);
};

export const updatePassword = (newPassword, { services }) => {
  const urlParams = window.location.search
    ? new URLSearchParams(window.location.search)
    : null;

  if (urlParams && urlParams.get("reset-token")) {
    const { password } = services;

    return fetch(`${password.url}/${password.basePath}/password/update`, {
      method: "PUT",
      body: JSON.stringify({
        newPassword,
        resetToken: urlParams.get("reset-token")
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    })
      .then(res => res.text())
      .then(data => (data ? JSON.parse(data) : {}))
      .catch(error => error);
  }

  throw Error("Missing password reset token.");
};

export const resetPassword = (
  { username, currentPassword, newPassword },
  { services }
) => {
  const { password } = services;

  return fetch(`${password.url}/${password.basePath}/password/resetrequest`, {
    method: "PUT",
    body: JSON.stringify({ newPassword }),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: btoa(`${username}:${currentPassword}`)
    }
  })
    .then(res => res.text())
    .then(data => (data ? JSON.parse(data) : {}))
    .catch(error => error);
};
