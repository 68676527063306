import React, { Component } from "react";
import { shape, object, func, bool } from "prop-types";
import {
  Modal,
  FormControl,
  Button,
  Preloader,
  FormGroup,
  ControlLabel
} from "@icg360/ui-toolkit";
import { configPropTypes } from "../config";
import { forgotPassword } from "../utils/user";

export default class ForgotPassword extends Component {
  els = {};

  static propTypes = {
    config: shape(configPropTypes).isRequired,
    history: object.isRequired,
    setAlert: func.isRequired,
    showModal: bool.isRequired
  };

  state = {
    username: "",
    isLoading: false,
    invalidUsername: true
  };

  saveRef = name => ref => {
    this.els[name] = ref;
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      invalidUsername: e.target.value === ""
    });
  };

  handleReset = e => {
    e.preventDefault();
    const { config } = this.props;
    const { username } = this.state;
    this.setState({
      isLoading: true
    });
    forgotPassword(username, config).then(res => {
      this.setState({
        isLoading: false
      });
      const err = res.errCode === 500;
      this.setAlert(err);
    });
  };

  setAlert = err => {
    const { history, setAlert } = this.props;
    setAlert(err);
    history.push("/");
  };

  close = () => {
    const { history } = this.props;
    history.push("/");
  };

  render() {
    const { username, isLoading, invalidUsername } = this.state;
    const {
      showModal,
      config: {
        customer: { footerSupportUrl }
      }
    } = this.props;
    return (
      <Modal bsSize="small" onHide={this.close} show={showModal}>
        <form
          className="forgot-password__form"
          disabled={isLoading}
          onSubmit={this.handleReset}
        >
          <Modal.Header closeButton>
            <Modal.Title>Forgot Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Enter your username and we’ll send an email to the address on
              record with a link to reset your password.
            </p>
            <p>
              Can’t remember your username? Click{" "}
              <a
                rel="noopener noreferrer"
                href={`${footerSupportUrl}/group/81/create/300`}
                target="_blank"
              >
                HERE
              </a>{" "}
              to submit a support ticket.
            </p>
            <FormGroup>
              <ControlLabel htmlFor="username">Username</ControlLabel>
              <FormControl
                disabled={isLoading}
                id="username"
                inputRef={this.saveRef("username")}
                name="username"
                onChange={this.handleInputChange}
                type="text"
                value={username}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <div className="forgot-password__form--field">
              <div className="forgot-password__form--button">
                <Button
                  bsStyle="primary"
                  disabled={isLoading || invalidUsername}
                  type="submit"
                >
                  {isLoading ? (
                    <Preloader
                      color="#fff"
                      className="forgot-password__preloader--button"
                      size="20"
                    />
                  ) : (
                    <span className="text">Request Password Reset</span>
                  )}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}
