// eslint-disable-next-line no-unused-vars
const template = {
  bulletPoints: ["Bullet 1", "Bullet 2"],
  url: "",
  version: "1.58.0",
  date: "2021-03-11"
};

export default [
  {
    bulletPoints: [
      "Adds Reverse Write Off Charge as a Reason Code to Apply Charge PM Action",
      "Adds State column to Referrals module",
      "Removes Type column and filtering from Referrals module",
      "Adds support for address autocomplete to FNOL"
    ],
    url: "",
    version: "1.75.0",
    date: "2021-07-07"
  },
  {
    bulletPoints: [
      "Adds ability to schedule Broker of Record Changes at renewal",
      "Allows documents that have been deleted from print to be reprinted"
    ],
    url: "",
    version: "1.74.0",
    date: "2021-06-30"
  },
  {
    bulletPoints: [
      "Adds additional Reason Codes to hardcode cancellations",
      "Removes requirement for timestamp in What's New",
      "Bugfix: Past term dates are disabled in 'Cancel' action Effective Date Calendar",
      "Adds high priority end to end tests"
    ],
    url: "",
    version: "1.73.0",
    date: "2021-06-23"
  },
  {
    bulletPoints: [
      "Removes Legacy View access for Quotes",
      "Allows backdating of cancellations to previous term of the policy",
      "Updates the version of FNOL for bug fixes",
      "Updates Post-Prod Hostnames",
      "Adds end to end tests"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2923959948/IPC+Manager+1.72.0",
    version: "1.72.0",
    date: "2021-06-16",
    time: "21:00"
  },
  {
    bulletPoints: [
      "Adds automated test cases",
      "Adds deployment support for post prod environments"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2911666256/IPC+Manager+1.71.0",
    version: "1.71.0",
    date: "2021-06-09"
  },
  {
    bulletPoints: [
      "Adds Launch Darkly and Config support for post prod environments",
      "Adds automated test cases",
      "Limits Legacy View Functionality to IPM Changes only"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2886140015/IPC+Manager+1.70.0",
    version: "1.70.0",
    date: "2021-06-02"
  },
  {
    bulletPoints: [
      "Hides referrals that are assigned to producers in the Referrals module",
      "Adds popup to system status in the footer with a link to the the Status Page",
      "Bugfix: Incorrect ALC displayed in Policy Vitals",
      "Bugfix: Do-not-reinstate flag not displayed in Summary"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2854551953/IPC+Manager+1.69.0",
    version: "1.69.0",
    date: "2021-05-26"
  },
  {
    bulletPoints: [
      "Updates FNOL module for bug fixes",
      "Bugfix: Can’t change Effective Date in Claims tab"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2817228967/IPC+Manager+1.68.0",
    version: "1.68.0",
    date: "2021-05-19"
  },
  {
    bulletPoints: [
      "Adds ACH refunds to the Disbursements module",
      "Bugfix: Prefilling Mailing Address instead of Property Address in FNOL form",
      "Bugfix: Incorrect Carrier Group ID used for FNOL submission"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2782495666/IPC+Manager+1.67.0",
    version: "1.67.0",
    date: "2021-05-13"
  },
  {
    bulletPoints: [
      "Adds SonarQube",
      "Adds refresh of Claims when FNOL is submitted",
      "Updates version for FNOL component"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2740946150/IPC+Manager+1.66.0",
    version: "1.66.0",
    date: "2021-05-04"
  },
  {
    bulletPoints: [
      "When an ODEN Pending Cancellation is present, that cancellation reason will now be available when processing a hardcode cancel"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2706703815/IPC+Manager+1.65.0",
    version: "1.65.0",
    date: "2021-04-28"
  },
  {
    bulletPoints: [
      "Visual enhancements to Inspections",
      "Updates Claims data source to include FNOL",
      "Bugfix: GraphQL error when resending Docusign documents"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2672494111/IPC+Manager+1.64.0",
    version: "1.64.0",
    date: "2021-04-20"
  },
  {
    bulletPoints: [
      "Adds Notifications function for users to view Agent Portal notices",
      "Adds First Notice of Loss feature"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2642411764/IPC+Manager+1.63.0",
    version: "1.63.0",
    date: "2021-04-13"
  },
  {
    bulletPoints: [
      "Adds Inspections to Policy View",
      "Bugfix: Able to select cancellation date prior to effective date of the policy"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2614034460/ICP+Manager+1.62.0",
    version: "1.62.0",
    date: "2021-04-07"
  },
  {
    bulletPoints: [
      "Adds Sure and SureChoice carrier filters to Referrals module",
      "Changes display Premium and Total Premium to Grand total on preview screen of PM Actions for TX Sure",
      "Bugfix: Select All button misaligned for filtering in Referrals module"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2581758091/IPC+Manager+1.61.0",
    version: "1.61.0",
    date: "2021-03-31"
  },
  {
    bulletPoints: [
      "Adds Notifications to give users visibility into IPC Manager updates",
      "Updates claims contact information for Sure and SureChoice",
      "Removes Agent Support View",
      "Bugfix: sub navigation won't scroll in some sections when too many items are present"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2550399259/IPC+Manager+1.60.0",
    version: "1.60.0",
    date: "2021-03-24"
  },
  {
    bulletPoints: [
      "Bugfix: Binding Producer information not displayed on Summary",
      "Bugfix: Insured Email misaligned on Preview screen of Policy Management Actions",
      "Adds unit test",
      "Updates to ePay modal to Formik",
      "Updates test and consolidates to Organizations endpoint",
      "Updates react-query for configurations to be app wide instead of specific to Quotes and Policies module"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2524316008/IPC+Manager+1.59.0",
    version: "1.59.0",
    date: "2021-03-17"
  },
  {
    bulletPoints: [
      "Updates Claims Tracker to 1.3.2",
      "Suppresses API calls for ALC data when parameters not available",
      "Reorganizes sidebar in Auth-manager module",
      "Deprecates legacy Documents and Attachments proxy",
      "Utilizes ixAuth for user rights and roles",
      "Adds unit test for Activities and Activities List"
    ],
    url:
      "https://icg360.atlassian.net/wiki/spaces/SSPS/blog/2021/03/10/2495677116/IPC+Manager+1.58.0",
    version: "1.58.0",
    date: "2021-03-10"
  },
  {
    bulletPoints: [
      "Adds Producer Power-Up filter to Referrals module",
      "Updates the contact information displayed in the Claims section of Policy view"
    ],
    url: "",
    version: "1.57.0",
    date: "2021-03-03"
  }
];
