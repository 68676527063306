import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { shape, object } from "prop-types";
import {
  FormControl,
  Button,
  Preloader,
  FormGroup,
  ControlLabel,
  InputGroup,
  HelpBlock,
  Icon,
  Alert
} from "@icg360/ui-toolkit";
import { updatePassword, resetPassword } from "../utils/user";
import { configPropTypes } from "../config";
import "./ResetPassword.css";

const RESET_TYPES = {
  FORGOT: "forgot",
  RESET: "reset"
};

class ResetPassword extends Component {
  resetType = new URLSearchParams(window.location.search).has("reset-token")
    ? RESET_TYPES.FORGOT
    : RESET_TYPES.RESET;

  els = {};

  static propTypes = {
    config: shape(configPropTypes).isRequired,
    user: object,
    history: object.isRequired
  };

  static defaultProps = {
    user: {}
  };

  state = {
    newPassword: "",
    currentPassword: "",
    isLoading: false,
    validLength: false,
    hasNum: false,
    hasUppercase: false,
    hasSpaces: false,
    showPassword: false,
    success: false,
    error: false
  };

  componentDidMount() {
    this.tryAutoFocus(
      this.resetType === RESET_TYPES.FORGOT ? "newPassword" : "currentPassword"
    );
  }

  saveRef = name => ref => {
    this.els[name] = ref;
  };

  tryAutoFocus = (el = "newPassword") => {
    if (this.els[el]) {
      this.els[el].focus();
    }
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleNewPasswordChange = e => {
    const newPassword = e.target.value;
    const validLength = !(newPassword.length < 8 || newPassword.length > 32);
    const hasNum = /\d+/.test(newPassword);
    const uppercaseReg = new RegExp("^(?=.*[A-Z])");
    const hasUppercase = !!newPassword.match(uppercaseReg);
    const hasSpaces = !/\s/g.test(newPassword);
    this.setState({
      [e.target.name]: newPassword,
      validLength,
      hasNum,
      hasUppercase,
      hasSpaces
    });
  };

  handleReset = e => {
    e.preventDefault();
    const {
      config,
      user,
      user: { username }
    } = this.props;
    const { currentPassword, newPassword } = this.state;
    this.setState({
      isLoading: true
    });

    (this.resetType === RESET_TYPES.RESET
      ? resetPassword({ username, currentPassword, newPassword }, config)
      : updatePassword(newPassword, config)
    ).then(res => {
      if (res.errCode && res.errMessage) {
        this.setState({
          isLoading: false,
          error: res.errMessage
        });
      } else {
        const success = user
          ? "Your password has been reset."
          : "Your password has been reset. You'll automatically be redirected to the login page";
        this.setState({
          isLoading: false,
          newPassword: "",
          validLength: false,
          hasNum: false,
          hasUppercase: false,
          hasSpaces: false,
          success
        });
      }
    });
  };

  showPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  };

  renderCheckbox = checkboxState =>
    checkboxState ? (
      <Icon
        className="reset-password__icon"
        name="check_box"
        style={{ color: "#000" }}
      />
    ) : (
      <Icon
        className="reset-password__icon"
        name="check_box_outline"
        style={{ color: "#ccc" }}
      />
    );

  render() {
    const {
      isLoading,
      newPassword,
      currentPassword,
      validLength,
      hasNum,
      hasUppercase,
      hasSpaces,
      showPassword,
      success,
      error
    } = this.state;
    const { user, history } = this.props;
    return (
      <div className="reset-password">
        {success && (
          <Alert
            bsStyle="success"
            isGlobal
            autoDismiss
            onDismiss={() => {
              this.setState({ success: false });
              if (Object.entries(user).length === 0) {
                history.replace("/");
              } else {
                history.goBack();
              }
            }}
          >
            <p>
              <strong>Success! </strong>
              {success}
            </p>
          </Alert>
        )}
        {error && (
          <Alert
            bsStyle="danger"
            isGlobal
            autoDismiss
            onDismiss={() => {
              this.setState({ error: false });
            }}
          >
            <p data-bdd="login-failed">
              <strong>Reset failed. </strong>
              {error}
            </p>
          </Alert>
        )}
        <form
          className="reset-password__form container-fluid"
          disabled={isLoading}
          onSubmit={this.handleReset}
        >
          <div className="reset-password__container">
            <div className="reset-password__header">
              <h2>Reset Password</h2>
            </div>
            <div className="reset-password__body">
              {this.resetType === RESET_TYPES.RESET && (
                <FormGroup>
                  <ControlLabel htmlFor="currentPassword">
                    Current Password
                  </ControlLabel>
                  <FormControl
                    disabled={isLoading}
                    id="currentPassword"
                    inputRef={this.saveRef("currentPassword")}
                    name="currentPassword"
                    onChange={this.handleInputChange}
                    type="password"
                    value={currentPassword}
                    autoComplete="password"
                  />
                </FormGroup>
              )}

              <FormGroup>
                <ControlLabel htmlFor="newPassword">New Password</ControlLabel>
                <InputGroup className="reset-password__search">
                  <FormControl
                    disabled={isLoading}
                    id="newPassword"
                    inputRef={this.saveRef("newPassword")}
                    name="newPassword"
                    onChange={this.handleNewPasswordChange}
                    type={showPassword ? "text" : "password"}
                    value={newPassword}
                    autoComplete="new-password"
                  />
                  <InputGroup.Button>
                    <Button
                      className="reset-password__search--button"
                      onClick={this.showPassword}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputGroup.Button>
                </InputGroup>
                <HelpBlock>
                  Your password must meet the following requirements:{" "}
                  <ul>
                    <li>
                      {this.renderCheckbox(validLength)} Between 8 and 32
                      characters
                    </li>
                    <li>{this.renderCheckbox(hasNum)} A number</li>
                    <li>
                      {this.renderCheckbox(hasUppercase)} An uppercase character
                    </li>
                    <li>{this.renderCheckbox(hasSpaces)} No spaces</li>
                  </ul>
                </HelpBlock>
              </FormGroup>
            </div>
            <div className="reset-password__footer">
              <Button
                bsStyle="primary"
                disabled={
                  isLoading ||
                  !(validLength && hasNum && hasUppercase && hasSpaces)
                }
                type="submit"
              >
                {isLoading ? (
                  <Preloader
                    color="#fff"
                    className="reset-password__preloader--button"
                    size="20"
                  />
                ) : (
                  <span className="text">Reset Password</span>
                )}
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default withRouter(ResetPassword);
