import React from "react";
import styled from "styled-components";
import { Icon } from "@icg360/ui-toolkit";
import PropTypes from "prop-types";
import moment from "moment";
import useWhatsNew from "../hooks/useWhatsNew";
import { userPropTypes } from "../utils/user";
import {
  CenterWrapper,
  DateTime,
  Notes,
  Note,
  Title,
  Page,
  PageHeader
} from "./shared/Notifications";

const ReleaseNoteViewMore = styled.a`
  align-self: flex-end;
  color: #333333;
  font-size: 14px;
  line-height: 21px;

  i {
    margin-right: 7px;
  }
`;

const BulletPoints = styled.ul`
  display: grid;
  grid-gap: 11px;
  list-style-position: outside;
  padding-left: 0;
  margin-left: 1em;
`;

const BulletPoint = styled.li`
  color: #4f4f4f;
  font-size: 14px;
  line-height: 21px;
`;

const ViewOldReleaseNotes = styled.a`
  align-self: flex-end;
  color: #333333;
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;

  i {
    margin-right: 8px;
  }
`;

const WhatsNew = ({ user: { id: userId } }) => {
  const { releaseNotes, alreadyViewedReleaseNoteVersions } = useWhatsNew({
    userId,
    shouldMarkAsViewed: true
  });

  return (
    <Page>
      <CenterWrapper>
        <PageHeader>What’s New in IPC Manager</PageHeader>
        <Notes>
          {releaseNotes.map(({ date, bulletPoints, version, url }) => (
            <Note
              data-bdd="release-note"
              isNew={!alreadyViewedReleaseNoteVersions.includes(version)}
              key={version}
            >
              <DateTime date={moment(date).valueOf()} shouldHideTime />
              <Title>Version {version}</Title>
              <BulletPoints>
                {bulletPoints.map(bulletPoint => (
                  <BulletPoint key={bulletPoint}>{bulletPoint}</BulletPoint>
                ))}
              </BulletPoints>
              {url && (
                <ReleaseNoteViewMore
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="launch" size="xsmall" />
                  View More
                </ReleaseNoteViewMore>
              )}
            </Note>
          ))}
        </Notes>
        <ViewOldReleaseNotes
          href="https://icg360.atlassian.net/wiki/spaces/SSPS/pages/2266333473/Software+Release+Notes"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="launch" size={18} />
          View Older Release Notes
        </ViewOldReleaseNotes>
      </CenterWrapper>
    </Page>
  );
};

WhatsNew.propTypes = {
  user: PropTypes.shape(userPropTypes).isRequired
};

export default WhatsNew;
