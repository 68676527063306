// This does get resolved when you bundle it with it's parent IPC Manager
import PolicyParser from "@package/policy-parser";

const getPolicy = ({ pxcentral: { url, basePath }, id }) =>
  fetch(`${url}/${basePath}/policies/${id}`, {
    mode: "cors",
    credentials: "include",
    headers: {
      "X-ICG-Client": "true"
    }
  })
    .then(response => {
      if (!response.ok) {
        throw Error("No policy was found.");
      }
      return response.text();
    })
    .then(xml => ({
      id,
      ...PolicyParser.parse(xml)
    }));

export default ({ services: { pxcentral } }) => ({
  Query: {
    policy: (root, { id }) => getPolicy({ pxcentral, id })
  }
});
