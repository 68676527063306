import { Alert } from "@icg360/ui-toolkit";
import "@icg360/ui-toolkit/react-day-picker/lib/style.css";
import loadGraphClient from "@package/ipcmgr-graphql-client";
import "@package/ipcmgr-toolkit/css/styles.css";
import "@package/ipcmgr-toolkit/css/variables.css";
import "@package/ipcmgr-toolkit/css/modals.css";
import "@package/ipcmgr-toolkit/css/utility.css";
import React from "react";
import { ApolloProvider } from "react-apollo";
import ReactDOM from "react-dom";
import { TrackJS } from "trackjs";
import App from "./components/App";
import { loadRuntimeConfig } from "./config";
import queryConfig from "./graphql/config.graphql";
import { initTracking } from "./utils/tracking";
import { checkSession } from "./utils/user";
import packageJSON from "../package.json";

const installTrackJS = ({ environmentId }) =>
  TrackJS.install({
    token: "76bc2e107fcd4007bdf4b17654b7d041",
    application: `ipc_manager`,
    version: packageJSON.version,
    onError: payload =>
      payload.metadata.push({
        key: "Environment Id",
        value: environmentId
      })
  });

const rootNode = document.getElementById("app");

const flattenConfig = items =>
  items.reduce(
    (acc, { id, value, values }) => ({
      ...acc,
      [id]: value || values
    }),
    {}
  );

const mergeConfigs = (config, graphqlClient) =>
  graphqlClient
    .query({ query: queryConfig })
    .then(({ data: { ipc, ipcm } }) => ({
      ...config,
      ipc: flattenConfig(ipc),
      ipcm: flattenConfig(ipcm)
    }))
    .catch(e => {
      // eslint-disable-next-line no-console
      console.warn(e);
      return { ...config, ipc: {}, ipcm: {} };
    });

// We find out information about the environment and instance at runtime.
// Based on that information, we load instance-specific information before
// mounting the application.
loadRuntimeConfig()
  .then(async config => {
    const POLLY_RECORDING_URL_PARAM = "polly_recording";
    const params = new URLSearchParams(window.location.search);

    // Only load PollyJS if in stage environment and requested
    if (
      config.environmentId === "stage" &&
      params.has(POLLY_RECORDING_URL_PARAM)
    ) {
      const recordingName = params.get(POLLY_RECORDING_URL_PARAM);

      const { default: initializePollyJS } = await import("./utils/polly");
      initializePollyJS(config.pollyJSServer, recordingName);
    }
    return config;
  })
  .then(config =>
    Promise.all([
      config,
      loadGraphClient(Object.values(config.graphql).filter(i => i), config)
    ])
  )
  .then(([config, graphqlClient]) =>
    Promise.all([
      mergeConfigs(config, graphqlClient),
      graphqlClient,
      checkSession(config.services.url)
    ])
  )
  .then(([config, graphqlClient, identifiedUser]) => {
    initTracking(config.mixpanelToken);
    installTrackJS(config);

    ReactDOM.render(
      <ApolloProvider client={graphqlClient}>
        <App config={config} identifiedUser={identifiedUser} />
      </ApolloProvider>,
      rootNode
    );
  })
  .catch(err => {
    // eslint-disable-next-line no-console
    console.error(err);
    ReactDOM.render(
      <Alert isGlobal bsStyle="warning">
        The application failed to load.
        {process.env.NODE_ENV === "development"
          ? " Try connecting to the VPN or look for errors in the console."
          : " Try refreshing the page, or contact support for help."}
      </Alert>,
      rootNode
    );
  });
