import React from "react";
import { MODULES } from "@package/ipcmgr-toolkit";

const imports = {
  "transaction-batches": () => import("@module/transaction-batches"),
  "ipc-configuration": () => import("@module/ipc-configuration"),
  "quick-view": () => import("@module/quick-view"),
  "quotes-and-policies": () => import("@module/quotes-and-policies"),
  disbursements: () => import("@module/disbursements"),
  referrals: () => import("@module/referrals"),
  renewals: () => import("@module/renewals"),
  "renewal-underwriting": () => import("@module/renewal-underwriting"),
  "auth-manager": () => import("@module/auth-manager")
};

const routes = [
  {
    label: "IPC Manager",
    path: "/",
    component: React.lazy(() => import("./components/Home"))
  },
  ...MODULES.map(({ key, abbr, label, flag, roles }) => ({
    abbr,
    label,
    path: `/${key}`,
    flag,
    roles,
    component: React.lazy(imports[key])
  }))
];

export default routes;
