class PolicyHandler {
  constructor(strict) {
    const defaults = {
      strict,
      debug: false,

      inParent: null,

      depth: 0,
      active: false,

      identifiers: {},
      inIdentifiers: false,

      management: {},
      inManagement: false,

      accounting: {},
      inAccounting: false,
      tmpInstallments: [],
      tmpLineItem: null,

      customers: {},
      inCustomers: false,

      riskAnalyses: {},
      inRiskAnalyses: false,

      tmpTermMeta: {},
      tmpTermData: {},
      terms: [],
      inTerms: false,
      inQuoting: false,
      inCurrentQuote: false,
      inProductRef: false,

      tmpIntervalData: {},
      inIntervals: false,

      rating: {},
      inRating: false,

      relatedItems: null,
      inRelatedItems: false,
      tmpItem: null,

      tmpName: null,
      tmpValue: null,

      eventHistory: [],
      inEventHistory: false,
      tmpEvent: null,
      tmpEventItem: null,

      revisionHistory: [],
      inRevisionHistory: false,
      tmpRevision: null,
      tmpRevisionItem: null,

      references: {},
      referenceTypes: {
        Documents: "documents",
        Inspections: "inspections",
        RiskAnalyses: "riskAnalyses",
        Profiles: "profiles",
        WorkflowJobs: "workflowJobs",
        OtherReferences: "otherReferences"
      },
      currentReference: null,
      tmpReference: null,

      currentRulesetResult: null,
      tmpRulesetResult: null,
      rulesetResults: [],
      inRulesetResults: false
    };
    this.init(defaults);
  }

  init(defaults) {
    Reflect.ownKeys(defaults).forEach(k => {
      this[k] = defaults[k];
    });
  }

  topLevelDepth() {
    return this.depth === 2;
  }

  handleIdentifiers(name, attrs, open) {
    if (this.topLevelDepth() && name === "Identifiers") {
      this.inIdentifiers = open;
      this.active = open;
      return;
    }
    if (open) {
      if (this.inIdentifiers && name === "Identifier") {
        const { name: attrName, value } = attrs;
        this.identifiers[attrName] = value;
      }
    }
  }

  handleEventOrRevisionHistory(name, attrs, open, resourceType) {
    if (this.topLevelDepth() && name === `${resourceType}History`) {
      this[`in${resourceType}History`] = open;
      this.active = open;
      return;
    }
    const tmpResourceKey = `tmp${resourceType}`;
    const tmpResourceItemKey = `${tmpResourceKey}Item`;
    if (this[`in${resourceType}History`]) {
      if (open) {
        const { name: attrName, value, ...rest } = attrs;
        // handles <DataItem name="foo" value="bar"></DataItem>
        if (attrName) {
          if (this[tmpResourceKey] && name === "DataItem") {
            this[tmpResourceKey][attrName] = value;
          }
        } else if (name === resourceType) {
          this[tmpResourceKey] = { ...attrs };
        } else if (!value) {
          // handles <Name attr1="foo">Value</Name>
          if (Object.keys(rest).length > 0) {
            this[tmpResourceItemKey] = {
              attrs,
              name
            };
          }
          // handles <Name>Value</Name>
          this.tmpName = name;
        }
      } else if (this.tmpName && this.tmpValue) {
        // fold in tmps if they're there

        if (this[tmpResourceKey]) {
          if (this[tmpResourceItemKey]) {
            this[tmpResourceItemKey][this.tmpName] = this.tmpValue;
            this.tmpValue = {
              value: this.tmpValue,
              ...this[tmpResourceItemKey].attrs
            };
            this.tmpName = this[tmpResourceItemKey].name;
            this[tmpResourceItemKey] = null;
          }
          this[tmpResourceKey] = {
            ...this[tmpResourceKey],
            [this.tmpName]: this.tmpValue
          };
        }
        this.tmpName = null;
        this.tmpValue = null;
      }
    }

    if (!open && this[tmpResourceKey] && name === resourceType) {
      const sectionName = `${resourceType.toLowerCase()}History`;
      this[sectionName].push(this[tmpResourceKey]);
      this[tmpResourceKey] = null;
    }
  }

  handleEventHistory(name, attrs, open) {
    this.handleEventOrRevisionHistory(name, attrs, open, "Event");
  }

  handleRevisionHistory(name, attrs, open) {
    this.handleEventOrRevisionHistory(name, attrs, open, "Revision");
  }

  handleAccounting(name, attrs, open) {
    if (this.topLevelDepth() && name === "Accounting") {
      this.inAccounting = open;
      this.active = open;
      return;
    }
    if (this.inAccounting) {
      if (open) {
        // handle name/value type
        const { name: attrName, value, ...rest } = attrs;
        if (attrName) {
          if (this.tmpLineItem) {
            this.tmpLineItem = {
              ...this.tmpLineItem,
              [attrName]: value
            };
          } else if (this.depth === 3) {
            if (this.accounting[attrName]) {
              return;
            }
            this.accounting[attrName] = value;
          } else {
            // handle nested values
            this.accounting[this.tmpName] = {
              ...this.accounting[this.tmpName],
              [attrName]: value
            };
          }
          return;
        }

        // handle <Name>Value</Name> type
        if (Object.keys(rest).length === 0) {
          if (name === "Ledger") {
            this.accounting[name] = { LineItems: [], ...rest };
            return;
          }
          this.tmpName = name;
        }

        // handle <Name attr1="" attr2="" />
        if (Object.keys(rest).length > 0) {
          if (name === "PaymentPlan") {
            this.accounting[name] = { Installments: [], ...rest };
          } else if (name === "Installment") {
            this.accounting.PaymentPlan.Installments.push(rest);
          } else if (name === "LineItem") {
            this.tmpLineItem = attrs;
          } else {
            this.accounting[name] = rest;
          }
        }
      } else if (this.tmpName && this.tmpValue) {
        // fold in tmps if they're there
        if (this.tmpLineItem) {
          this.tmpLineItem = {
            ...this.tmpLineItem,
            [this.tmpName]: this.tmpValue
          };
        } else if (!this.accounting[this.tmpName]) {
          this.accounting[this.tmpName] = this.tmpValue;
        }
        this.tmpName = null;
        this.tmpValue = null;
      }
    }

    // handle closing
    if (!open && this.tmpLineItem) {
      if (name === "LineItem") {
        this.accounting.Ledger.LineItems.push(this.tmpLineItem);
        this.tmpLineItem = null;
      }
    }
  }

  handleManagement(name, attrs, open) {
    if (this.topLevelDepth() && name === "Management") {
      this.inManagement = open;
      this.active = open;
      return;
    }
    if (this.inManagement) {
      if (open) {
        if (name === "DataItem") return;
        // handle name/attr type
        const { name: attrName, value, ...rest } = attrs;
        if (attrName) {
          if (this.depth === 3) {
            if (this.management[attrName]) {
              return;
            }
            this.management[attrName] = value;
          } else {
            // handle nested values
            this.management[this.tmpName] = {
              ...this.management[this.tmpName],
              [attrName]: value
            };
          }
          return;
        }

        // handle <Name>Value</Name> type
        if (Object.keys(rest).length === 0 || name === "PolicyState") {
          this.tmpName = name;
        }

        // handle <Name attr1="" attr2="" />
        if (Object.keys(rest).length > 0) {
          const key = name === "PolicyState" ? "PolicyStateAttrs" : name;
          this.management[key] = rest;
        }
      } else if (this.tmpName && this.tmpValue) {
        // fold in tmps if they're there
        if (!this.management[this.tmpName]) {
          this.management[this.tmpName] = this.tmpValue;
        }
        this.tmpName = null;
        this.tmpValue = null;
      }
    }
  }

  handleCustomers(name, attrs, open) {
    if (this.topLevelDepth() && name === "Customers") {
      this.inCustomers = open;
      this.active = open;
      return;
    }
    if (this.inCustomers && open) {
      if (name === "DataItem") {
        const { name: attrName, value } = attrs;
        if (attrName.match(/^Op[A-Z]/)) {
          this.customers[attrName.slice(2)] = value;
        } else if (!this.customers[attrName]) {
          this.customers[attrName] = value;
        }
      }
    }
  }

  handleTerms(name, attrs, open) {
    if (this.topLevelDepth() && (name === "Terms" || name === "Quoting")) {
      if (name === "Quoting") {
        this.inQuoting = open;
      } else {
        this.inTerms = open;
      }
      this.active = open;
      return;
    }
    // if inside term interval, let handleIntervals take it
    if (this.inIntervals || this.inRating) return;
    if (this.inTerms || this.inQuoting) {
      if (this.inQuoting && name === "CurrentQuote") {
        this.inCurrentQuote = open;
        return;
      }

      if (this.inQuoting && !this.inCurrentQuote) return;

      if (open) {
        // We only want the last term, so if this is a
        // new Term, reset previous term
        if (name === "Term") {
          this.tmpTermMeta = {};
          this.tmpTermData = {};
          this.tmpIntervalData = {};
          return;
        }

        // handle <DataItem name="" value="" />
        const { name: attrName, value, ...rest } = attrs;
        if (name === "DataItem") {
          if (attrName.match(/^Op[A-Z]/)) {
            this.tmpTermData[attrName.slice(2)] = value;
            return;
          }
        }

        if (name === "ProductRef") {
          this.inProductRef = open;
        }

        if (this.inProductRef && name === "CachedItem") {
          this.tmpTermData[`ProductRef${attrName}`] = value;
        }

        // handle <Name>Value</Name> type
        if (Object.keys(rest).length === 0) {
          this.tmpName = name;
        }
        return;
      }

      if (name === "Term" || name === "ProtoTerm") {
        this.terms.push({
          ...this.tmpIntervalData,
          ...this.tmpTermData,
          ...this.tmpTermMeta
        });
      } else if (this.tmpName && this.tmpValue) {
        // fold in tmps if they're there
        if (!this.tmpTermMeta[this.tmpName]) {
          this.tmpTermMeta[this.tmpName] = this.tmpValue;
        }
        this.tmpName = null;
        this.tmpValue = null;
      }
    }
  }

  handleIntervals(name, attrs, open) {
    if (
      (this.inTerms && name === "Intervals") ||
      (this.inQuoting && name === "ProtoInterval")
    ) {
      this.inIntervals = open;
      this.active = open;
      if (name === "ProtoInterval" && open) {
        this.tmpIntervalData = {};
      }
      return;
    }

    if (this.inIntervals && open) {
      // We only want the last interval, so if this is a
      // new interval, reset previous interval
      if (name === "Interval") {
        this.tmpIntervalData = {};
        return;
      }

      // handle <DataItem name="" value="" />
      const { name: attrName, value } = attrs;
      if (name === "DataItem") {
        // prioritize values beginning with Op prefix
        if (attrName.match(/^Op[A-Z]/)) {
          this.tmpIntervalData[attrName.slice(2)] = value;
        } else if (!this.tmpIntervalData[attrName]) {
          this.tmpIntervalData[attrName] = value;
        }
      }
    }
  }

  handleRating(name, attrs, open) {
    if (this.inCurrentQuote && name === "Rating") {
      this.inRating = open;
      this.active = open;
      return;
    }
    if (this.inRating && open) {
      if (name === "DataItem") {
        this.rating[attrs.name] = attrs.value;
      }
    }
  }

  handleRelatedItems(name, attrs, open) {
    if (name === "RelatedItems") {
      this.inRelatedItems = open;
      this.active = open;
      if (open)
        this.relatedItems = {
          Notes: [],
          Attachments: [],
          Tasks: []
        };
      return;
    }

    if (this.inRelatedItems) {
      if (open) {
        // do open stuff
        if (name === "Note" || name === "Attachment" || name === "Task") {
          this.tmpItem = attrs;
          return;
        }
        // handle <Name>Value</Name> type
        if (Object.keys(attrs).length === 0) {
          this.tmpName = name;
          return;
        }

        if (name === "TaskRef") {
          this.tmpItem[name] = attrs.idref;
        }
        if (name === "AttachmentRef") {
          if (this.tmpItem) {
            if (!this.tmpItem.attachmentIds) {
              this.tmpItem.attachmentIds = [];
            }
            this.tmpItem.attachmentIds.push(attrs.idref);
          }
        }
        if (name === "DataItem" && this.tmpItem) {
          if (attrs.name === "DocumentId") {
            if (!this.tmpItem.documentIds) this.tmpItem.documentIds = [];
            this.tmpItem.documentIds.push(attrs.value);
          } else {
            this.tmpItem[attrs.name] = attrs.value;
          }
        }
        return;
      }

      // do close stuff
      if (this.tmpName && this.tmpValue) {
        // console.log(`this.tmpItem`, this.tmpItem);
        // fold in tmps if they're there
        if (this.tmpItem) {
          this.tmpItem = {
            ...this.tmpItem,
            [this.tmpName]: this.tmpValue
          };
        }
        this.tmpName = null;
        this.tmpValue = null;
        return;
      }
      if (
        (this.tmpItem && name === "Note") ||
        name === "Attachment" ||
        name === "Task"
      ) {
        this.relatedItems[`${name}s`].push(this.tmpItem);
        this.tmpItem = null;
      }
    }
  }

  handleReferences(name, attrs, open) {
    if (this.topLevelDepth() && this.referenceTypes[name]) {
      this.currentReference = open ? name : null;
      this.active = open;
      if (open) {
        this.references[this.referenceTypes[name]] = [];
      }
      return;
    }

    if (this.currentReference) {
      if (open) {
        // do open stuff
        if (name === "Reference") {
          this.tmpReference = attrs;
          return;
        }
        if (name === "CachedItem") {
          const { name: attrName, value } = attrs;
          this.tmpReference = {
            ...this.tmpReference,
            [attrName]: value
          };
        }
      }

      // do close stuff
      if (this.tmpReference && name === "Reference") {
        this.references[this.referenceTypes[this.currentReference]].push(
          this.tmpReference
        );
        this.tmpReference = null;
      }
    }
  }

  handleRulesetResults(name, attrs, open) {
    if (this.topLevelDepth() && name === "Quoting") {
      this.inQuoting = open;
      this.active = open;
      return;
    }

    if (this.inQuoting) {
      if (this.inQuoting && name === "RulesetResults") {
        this.inRulesetResults = open;
        return;
      }

      if (this.inQuoting && !this.inRulesetResults) return;

      if (open) {
        // do open stuff
        if (name === "RulesetResult") {
          this.tmpRulesetResult = attrs;
          return;
        }
        if (name === "CachedItem") {
          const { name: attrName, value } = attrs;
          this.tmpRulesetResult = {
            ...this.tmpRulesetResult,
            [attrName]: value
          };
        }
      }

      // do close stuff
      if (this.tmpRulesetResult && name === "RulesetResult") {
        this.rulesetResults.push(this.tmpRulesetResult);
        this.tmpRulesetResult = null;
      }
    }
  }

  onopentag(name, attrs) {
    this.depth += 1;
    this.handleIdentifiers(name, attrs, true);
    this.handleManagement(name, attrs, true);
    this.handleCustomers(name, attrs, true);
    this.handleTerms(name, attrs, true);
    this.handleIntervals(name, attrs, true);
    this.handleRating(name, attrs, true);
    this.handleAccounting(name, attrs, true);
    this.handleRelatedItems(name, attrs, true);
    this.handleReferences(name, attrs, true);
    this.handleRevisionHistory(name, attrs, true);
    this.handleEventHistory(name, attrs, true);
    this.handleRulesetResults(name, attrs, true);
  }

  onclosetag(name) {
    this.handleIdentifiers(name, null, false);
    this.handleManagement(name, null, false);
    this.handleCustomers(name, null, false);
    this.handleTerms(name, null, false);
    this.handleIntervals(name, null, false);
    this.handleRating(name, null, false);
    this.handleAccounting(name, null, false);
    this.handleRelatedItems(name, null, false);
    this.handleReferences(name, null, false);
    this.handleRevisionHistory(name, null, false);
    this.handleEventHistory(name, null, false);
    this.handleRulesetResults(name, null, false);
    this.depth -= 1;
  }

  ontext(rawText) {
    if (!this.active && !rawText) return;
    // collapse whitespace
    const text = rawText.replace(/\s+/g, " ").trim();
    this.tmpValue = text;
  }

  onend() {
    this.result = {
      identifiers: this.identifiers,
      management: this.management,
      policyData: {
        ...this.customers,
        ...this.rating,
        ...this.terms.slice(-1)[0]
      },
      accounting: this.accounting,
      relatedItems: this.relatedItems,
      ...this.references,
      terms: this.terms,
      eventHistory: this.eventHistory,
      revisionHistory: this.revisionHistory,
      rulesetResults: this.rulesetResults
    };
  }
}

export default PolicyHandler;
