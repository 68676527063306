/* eslint-disable no-console */
import { debounce } from "lodash";
import PT from "prop-types";
import { useEffect } from "react";
import { checkSession } from "../utils/user";

const INACTIVITY_THRESHOLD = 1.2e6; // 20 minutes in milliseconds

// This will keep track of our current session to make sure it is still active
// When the page becomes active after a period of inactivity greater than INACTIVITY_THRESHOLD,
// the component will ask the Auth service if the session is still active

SessionMonitor.propTypes = {
  authServiceUrl: PT.string.isRequired,
  children: PT.node.isRequired,
  logout: PT.func.isRequired,
  userId: PT.string.isRequired
};

export default function SessionMonitor({
  authServiceUrl,
  children,
  logout,
  userId
}) {
  useEffect(() => {
    let isActive = true;
    const setInactive = debounce(() => {
      console.log("Time is up, setting inactive.");
      isActive = false;
    }, INACTIVITY_THRESHOLD);
    const handleActivity = () => {
      if (!isActive) {
        isActive = true;
        console.log(
          "Okay, since you were inactive, let's ask if the session is still valid."
        );
        checkSession(authServiceUrl)
          .then(user => {
            if (user && user.id === userId) {
              console.log("Still active! Carry on.");
            } else {
              console.log(
                "This user is no longer active. You will automatically be logged out."
              );
              logout(authServiceUrl);
            }
          })
          .catch(logout);
      }
      setInactive();
    };
    document.addEventListener("visibilitychange", handleActivity);
    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("keydown", handleActivity);
    document.addEventListener("scroll", handleActivity);
    return () => {
      document.removeEventListener("visibilitychange", handleActivity);
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("keydown", handleActivity);
      document.removeEventListener("scroll", handleActivity);
    };
  }, []);
  return children;
}
