import React, { useEffect, useState } from "react";
import releaseNotesComplete from "../releaseNotes";

const useWhatsNew = ({ shouldMarkAsViewed, userId }) => {
  const releaseNotes = releaseNotesComplete.slice(0, 15);
  const key = `${userId}:whatsNew`;

  const [
    alreadyViewedReleaseNoteVersions,
    setAlreadyViewedReleaseNoteVersions
  ] = useState([]);

  const updateAlreadyViewedReleaseNotes = () => {
    setAlreadyViewedReleaseNoteVersions(
      JSON.parse(localStorage.getItem(key) || "[]")
    );
  };

  useEffect(() => {
    updateAlreadyViewedReleaseNotes();

    if (shouldMarkAsViewed) {
      localStorage.setItem(
        key,
        JSON.stringify(releaseNotes.map(({ version }) => version))
      );
    }
  }, []);

  return {
    alreadyViewedReleaseNoteVersions,
    newReleaseNoteCount: releaseNotes.filter(
      ({ version }) => !alreadyViewedReleaseNoteVersions.includes(version)
    ).length,
    releaseNotes,
    updateAlreadyViewedReleaseNotes
  };
};

export default useWhatsNew;

export const withWhatsNew = Component => props => {
  /* eslint-disable react/prop-types */
  const {
    user: { id: userId }
  } = props;
  const { newReleaseNoteCount, updateAlreadyViewedReleaseNotes } = useWhatsNew({
    userId
  });
  /* eslint-enable react/prop-types */

  return (
    <Component
      {...props}
      newReleaseNoteCount={newReleaseNoteCount}
      updateAlreadyViewedReleaseNotes={updateAlreadyViewedReleaseNotes}
    />
  );
};
