import { hot } from "react-hot-loader/root";
import { shape } from "prop-types";
import React, { Component, Fragment } from "react";
import { BrowserRouter, Link, matchPath, Redirect } from "react-router-dom";
import { configPropTypes } from "../config";
import { userPropTypes } from "../utils/user";
import "./App.css";
import Footer from "./Footer";
import Header from "./Header";
import Login from "./Login";
import Main from "./Main";
import MainNav from "./MainNav";
import ResetPassword from "./ResetPassword";
import UserProvider from "./UserProvider";

// The build breaks if this is changed to be a "stateless function"
class App extends Component {
  static propTypes = {
    config: shape(configPropTypes).isRequired,
    identifiedUser: shape(userPropTypes)
  };

  static defaultProps = {
    identifiedUser: null
  };

  render() {
    const { config, identifiedUser } = this.props;
    const { loginRequiredRole } = config;
    const userWithRole =
      identifiedUser && identifiedUser.roles.includes(loginRequiredRole)
        ? identifiedUser
        : null;
    return (
      <BrowserRouter>
        <UserProvider
          config={config}
          identifiedUser={userWithRole}
          renderLogin={(login, location, history) => (
            <div className="app-shell">
              {matchPath(location.pathname, { path: "/reset-password" }) ? (
                <Fragment>
                  <Header config={config}>
                    <Link className="app-shell__header--link" to="/">
                      Login
                    </Link>
                  </Header>
                  <main>
                    {new URLSearchParams(location.search).has("reset-token") ? (
                      <ResetPassword config={config} history={history} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/"
                        }}
                      />
                    )}
                  </main>
                </Fragment>
              ) : (
                <Login
                  config={config}
                  onLogin={login}
                  showModal={
                    !!matchPath(location.pathname, { path: "/forgot-password" })
                  }
                  history={history}
                />
              )}
              <Footer config={config} />
            </div>
          )}
        >
          {(user, flags, logout) => (
            <div className="app-shell">
              <Header config={config}>
                <MainNav
                  config={config}
                  flags={flags}
                  user={user}
                  onLogout={logout}
                />
              </Header>
              <Main config={config} flags={flags} user={user} />
              <Footer config={config} />
            </div>
          )}
        </UserProvider>
      </BrowserRouter>
    );
  }
}

export default hot(App);
