import React, { Component } from "react";
import { node, shape } from "prop-types";
import { Link } from "react-router-dom";
import { Navbar } from "@icg360/ui-toolkit";

import { configPropTypes } from "../config";
import AppName from "./AppName";
import "./Header.css";

export default class Header extends Component {
  static propTypes = {
    children: node.isRequired,
    config: shape(configPropTypes).isRequired
  };

  render() {
    const {
      children,
      config: { customer, customerId }
    } = this.props;
    return (
      <Navbar inverse fluid>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/" data-track="Header: Logo" className="header-brand">
              <img
                alt={`${customer.displayName} logo`}
                src={`/customers/${customerId}/monarch-logo-glyph.png`}
              />
              <AppName />
            </Link>
          </Navbar.Brand>
        </Navbar.Header>
        {children}
      </Navbar>
    );
  }
}
