import React from "react";
import PropTypes from "prop-types";

import Status from "./Status";
import { configPropTypes } from "../config";
import "./Footer.css";

Footer.propTypes = {
  config: PropTypes.shape(configPropTypes).isRequired
};

export default function Footer({ config }) {
  const { customer, customerId, statusApiUrl, statusPageUrl } = config;

  return (
    <footer className="footer">
      <a
        href={customer.url}
        className="footer__logo footer__logo--customer"
        target="_blank"
        rel="noopener noreferrer"
        data-bdd="footer-customer-url"
        data-track="Footer: SageSure"
      >
        <img
          src={`/customers/${customerId}/footer-logo.svg`}
          width="51"
          height="36"
          alt={`${customer.displayName} logo`}
          title={customer.displayName}
          data-bdd="footer-customer-logo"
        />
      </a>
      <ul className="footer__list">
        <li>{`© ${new Date().getFullYear()} ${customer.displayName}`}</li>
        <li>
          Version <code>{__VERSION__}</code>
        </li>
        {customer.footerSupportUrl && (
          <li>
            <a
              href={customer.footerSupportUrl}
              target="_blank"
              rel="noopener noreferrer"
              data-bdd="footer-support-url"
              data-track="Footer: Contact Support"
            >
              Contact Support
            </a>
          </li>
        )}
        {statusApiUrl && <Status api={statusApiUrl} page={statusPageUrl} />}
      </ul>

      {/* @TODO where does this link to? */}
      <a
        href="https://icg360.com/"
        className="footer__logo footer__logo--ipc"
        target="_blank"
        rel="noopener noreferrer"
        data-bdd="footer-ipc-url"
        data-track="Footer: Insight Policy Cloud"
      >
        <img
          src="/footer-logo-ipc.svg"
          width="110"
          height="31"
          alt="Insight Policy Cloud logo"
          title="Insight Policy Cloud"
          data-bdd="footer-ipc-logo"
        />
      </a>
    </footer>
  );
}
