import {
  FLAG_DEFAULTS,
  makeCancelable,
  MODULES
} from "@package/ipcmgr-toolkit";
import { initialize } from "launchdarkly-js-client-sdk";
import { has } from "lodash";
import ixdirectoryIdentity from "../promises/ixdirectoryIdentity";

export const flagDefaults = MODULES.reduce(
  (acc, { flag }) => ({
    ...acc,
    [flag]: false
  }),
  FLAG_DEFAULTS
);

export const ixAdminRoles = ixadmin => {
  const roles = ixadmin.children.find(({ name }) => name === "Roles");
  return roles
    ? roles.children
        .filter(({ name }) => name === "Role")
        .map(({ text }) => text)
    : [];
};

export const ixAdminRights = ixadmin => {
  const pm = ixadmin.children.find(({ name }) => name === "PoliciesModule");
  const rights = pm
    ? pm.children.find(({ name }) => name === "Rights")
    : undefined;
  return rights
    ? rights.children
        .filter(({ name }) => name === "Right")
        .map(({ text }) => text)
    : [];
};

export const initFlags = (config, { id, roles }, overrideFlags = {}) =>
  makeCancelable(
    ixdirectoryIdentity(config, id)
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error("Failed to load ixadmin roles.", e);
        return null;
      })
      .then(identity => {
        const envName =
          config.environmentId === "prod" ? "production" : "staging";
        const ixadmin = (identity || []).applicationSettings.find(
          ({ applicationName, environmentName }) =>
            applicationName === "ixadmin" && environmentName === envName
        );
        const client = initialize(
          config.launchDarklyKey,
          {
            key: toLower(id),
            custom: {
              roles: toLower(roles),
              ixadminRoles: ixadmin ? ixAdminRoles(ixadmin) : [],
              ixadminRights: ixadmin ? ixAdminRights(ixadmin) : []
            }
          },
          {
            fetchGoals: false,
            streaming: true,
            sendEventsOnlyForVariation: true
          }
        );
        // All the modules are passed an object with they keys being LD flag
        // names and the values are the flag variation. We only want to send
        // an event to LD that a flag was used if the key on this object was
        // accessed, so we are using getters to call `variation` which will
        // return the flag variation as well as send an event to LD. These
        // getters are not transfered when making copies of the object so
        // instead we are creating a class with the getters as prototypes
        // so whenever an update happends we can just create a new instance.
        class Flags {}
        return client.waitForInitialization().then(() => {
          Object.keys({
            ...flagDefaults,
            ...client.allFlags(),
            ...overrideFlags
          }).forEach(key => {
            Object.defineProperty(Flags.prototype, key, {
              get() {
                return has(overrideFlags, key)
                  ? overrideFlags[key]
                  : client.variation(key, flagDefaults[key] || false);
              }
            });
          });
          return {
            client,
            flags: new Flags(),
            listen: update => {
              const onChange = () => {
                update(new Flags(), client);
              };
              client.on("change", onChange);
              return () => {
                client.off("change", onChange);
              };
            }
          };
        });
      })
  );

function toLower(stringOrArray) {
  if (Array.isArray(stringOrArray)) {
    return stringOrArray.map(item => toLower(item));
  }
  if (typeof stringOrArray === "string") {
    return stringOrArray.toLowerCase();
  }
  return stringOrArray;
}
