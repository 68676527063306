import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { SchemaLink } from "apollo-link-schema";
import {
  introspectSchema,
  makeExecutableSchema,
  makeRemoteExecutableSchema,
  mergeSchemas
} from "graphql-tools";
import policy from "./policy";

const clientGraphQL = {
  policy
};

const testPaths = (obj, type) => path =>
  obj[path] ||
  // eslint-disable-next-line no-console
  console.warn(
    `The ${type} graphql path for "${path}" was not found.
Only these ${type} paths are supported: ${Object.keys(obj).join(", ")}`
  );

export default (graphqlEndpoints, config) => {
  const { clientPaths, localPaths, remotePaths } = graphqlEndpoints.reduce(
    (acc, url = "") => {
      if (url.startsWith("client")) {
        return {
          ...acc,
          clientPaths: [...acc.clientPaths, url]
        };
      }
      if (url.startsWith("local")) {
        return {
          ...acc,
          localPaths: [...acc.localPaths, url]
        };
      }
      return {
        ...acc,
        remotePaths: [...acc.remotePaths, url]
      };
    },
    {
      clientPaths: [],
      localPaths: [],
      remotePaths: []
    }
  );
  return Promise.all([
    clientPaths
      .map(path => path.replace(/^client:/, ""))
      .filter(testPaths(clientGraphQL, "client"))
      .map(path => makeExecutableSchema(clientGraphQL[path](config))),
    localPaths.length
      ? import("@package/ipcmgr-graphql-mocks").then(
          ({ default: ipcGraphQL }) =>
            localPaths
              .map(path => path.replace(/^local:/, ""))
              .filter(testPaths(ipcGraphQL, "local"))
              .map(path => makeExecutableSchema(ipcGraphQL[path]))
        )
      : [],
    remotePaths.length
      ? Promise.all(
          remotePaths
            .map(uri => new HttpLink({ uri, credentials: "include" }))
            .map(link =>
              introspectSchema(link)
                .then(schema => makeRemoteExecutableSchema({ schema, link }))
                // eslint-disable-next-line no-console
                .catch(console.warn)
            )
        )
      : []
  ]).then(([clientSchemas, localSchemas, remoteSchemas]) => {
    const schema = mergeSchemas({
      schemas: [...clientSchemas, ...localSchemas, ...remoteSchemas].filter(
        i => i
      )
    });
    return new ApolloClient({
      link: new SchemaLink({ schema }),
      cache: new InMemoryCache()
    });
  });
};
