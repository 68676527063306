import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { QANDP_AGENT_PORTAL_NOTICES } from "@package/ipcmgr-toolkit";
import GET_NOTICES from "../graphql/notices.graphql";

const useNotices = ({ shouldMarkAsViewed, userId, flags = {} }) => {
  const size = 6;
  const [from, setFrom] = useState(0);

  const { data, loading } = useQuery(GET_NOTICES, {
    skip: !flags[QANDP_AGENT_PORTAL_NOTICES],
    variables: {
      from,
      size
    }
  });
  const total = data?.getAgentPortalNotices?.totalCount;
  const notices = data?.getAgentPortalNotices?.notices;
  const key = `${userId}:notices`;

  const [alreadyViewedNoticeIds, setAlreadyViewedNoticeIds] = useState([]);

  const updateAlreadyViewedNoticeIds = () => {
    setAlreadyViewedNoticeIds(JSON.parse(localStorage.getItem(key) || "[]"));
  };

  useEffect(
    () => {
      if (!notices) {
        return;
      }
      updateAlreadyViewedNoticeIds();

      if (shouldMarkAsViewed && from === 0) {
        localStorage.setItem(key, JSON.stringify(notices.map(({ id }) => id)));
      }
    },
    [notices]
  );

  return {
    alreadyViewedNoticeIds,
    from,
    isLoading: loading,
    newNoticesCount: notices?.filter(
      ({ id }) => !alreadyViewedNoticeIds.includes(id)
    )?.length,
    notices,
    setFrom,
    size,
    total,
    updateAlreadyViewedNoticeIds
  };
};

export default useNotices;

export const withNotices = Component => props => {
  /* eslint-disable react/prop-types */
  const {
    flags,
    user: { id: userId }
  } = props;
  /* eslint-enable react/prop-types */

  const { newNoticesCount, updateAlreadyViewedNoticeIds } = useNotices({
    flags,
    userId
  });

  return (
    <Component
      {...props}
      newNoticesCount={newNoticesCount}
      updateAlreadyViewedNoticeIds={updateAlreadyViewedNoticeIds}
    />
  );
};
