const require200Handler = res => {
  // ensures that we get a valid 200 response, otherwise we fail everything!
  if (!res.ok) {
    throw new Error("Failed to fetch required application resources.");
  }
  return res;
};

export const fetchJSON = (...args) =>
  fetch(...args)
    .then(require200Handler)
    .then(res => res.json());

export const fetchText = (...args) =>
  fetch(...args)
    .then(require200Handler)
    .then(res => res.text());
