import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";

export const Page = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 28px;
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 616px;
`;

export const PageHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 19px;
`;

export const Notes = styled.div`
  border-top: solid 2px #5b6b15;
  margin-bottom: 40px;
`;

export const Note = styled.div`
  background: ${({ isNew }) => (isNew ? "#FFFEE4" : "#ffffff")};
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const StyledDateTime = styled.div`
  color: #889425;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 5px;
`;

export const DateTime = ({ date, shouldHideTime }) => (
  <StyledDateTime>
    {moment(date).format(
      shouldHideTime ? "MMMM D, YYYY" : "MMMM D, YYYY - h:mma"
    )}
  </StyledDateTime>
);

DateTime.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  shouldHideTime: PropTypes.bool
};

export const Title = styled.div`
  color: #4f4f4f;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 10px;
`;
