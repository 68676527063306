import { pipe, reduce } from "./functional";

export const TYPE = "__GQL_TYPE__";
export const TYPE_ARRAY = "__GQL_TYPE_ARRAY__";
export const PARENT_KEYS = "__GQL_PARENT_KEYS__";

const mergeArrayObjects = arr =>
  arr.reduce(
    (acc, obj) => ({
      ...acc,
      ...obj
    }),
    {}
  );

const flattenObject = (obj, level = 0, objAcc = obj) => {
  // eslint-disable-next-line no-use-before-define
  const initial = recursiveFlatten(obj, level, objAcc);
  return pipe.objToArr(initial)(
    reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: Array.isArray(v) && k !== PARENT_KEYS ? TYPE_ARRAY : v
      }),
      {}
    )
  );
};

const recursiveFlatten = (obj, level, objAcc) =>
  pipe.objToArr(obj)(
    reduce((acc, [k, v]) => {
      if (k === PARENT_KEYS) return acc;
      const isArray = Array.isArray(v);
      const typeObj = (() => (isArray ? mergeArrayObjects(v) : v))();
      const typeKey = (() => (isArray ? k.slice(0, -1) : k))();
      if (typeof typeObj === "object") {
        // eslint-disable-next-line no-param-reassign
        obj[k] = isArray ? TYPE_ARRAY : TYPE;
        const result = {
          ...acc,
          ...flattenObject(typeObj, level + 1, acc),
          [typeKey]: typeObj,
          ...(level === 0
            ? {
                [PARENT_KEYS]: [
                  ...(acc[PARENT_KEYS] ? acc[PARENT_KEYS] : []),
                  k
                ]
              }
            : {})
        };
        return result;
      }
      return acc;
    }, objAcc)
  );

export default flattenObject;
