/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */

import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup
} from "@icg360/ui-toolkit";
import { bool, func, object, shape } from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { configPropTypes } from "../config";
import { login, logout } from "../utils/user";
import AppName from "./AppName";
import ForgotPassword from "./ForgotPassword";
import "./Login.css";

export default class Login extends Component {
  static isSafari() {
    return navigator.vendor === "Apple Computer, Inc.";
  }

  state = {
    username: "",
    password: "",
    isLoading: false,
    error: null,
    success: null
  };

  els = {};

  static propTypes = {
    config: shape(configPropTypes).isRequired,
    onLogin: func.isRequired,
    history: object.isRequired,
    showModal: bool.isRequired
  };

  componentDidMount() {
    this.tryAutoFocus();
  }

  saveRef = name => ref => {
    this.els[name] = ref;
  };

  tryAutoFocus = (el = "username") => {
    if (this.els[el]) {
      this.els[el].focus();
    }
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { config, onLogin } = this.props;
    const { username, password } = this.state;
    if (!username) {
      this.setState(
        {
          error: "Username is required."
        },
        this.tryAutoFocus
      );
      return;
    }
    if (!password) {
      this.setState(
        {
          error: "Password is required."
        },
        () => this.tryAutoFocus("password")
      );
      return;
    }
    this.setState({
      error: null,
      isLoading: true
    });
    login({
      username,
      password,
      servicesUrl: config.services.url
    }).then(({ status, ...user }) => {
      const { loginRequiredRole } = config;
      const success = status !== "error";
      const hasLoginRole =
        success &&
        (!loginRequiredRole || user.roles.includes(loginRequiredRole));
      if (hasLoginRole) {
        onLogin(user, this.tryAutoFocus);
      } else {
        if (success) {
          logout(config.services.url);
        }
        this.setState(
          {
            isLoading: false,
            error:
              "Invalid credentials or system unavailable. Please try again."
          },
          this.tryAutoFocus
        );
      }
    });
  };

  setAlert = err => {
    if (err) {
      this.setState({
        error:
          "Something went wrong. If this problem persists, please contact support@sagesure.com"
      });
    } else {
      this.setState({
        success:
          "An email was sent for your username with a link to reset your password. Problems? Contact support@sagesure.com."
      });
    }
  };

  render() {
    const { config, showModal, history } = this.props;
    const { username, password, isLoading, error, success } = this.state;
    const { customer, customerId } = config;

    return (
      <main className="login bg-primary">
        {success && (
          <Alert
            bsStyle="success"
            isGlobal
            autoDismiss
            onDismiss={() => {
              this.setState({ success: null });
            }}
          >
            <p>
              <strong>Success! </strong>
              {success}
            </p>
          </Alert>
        )}
        {error && (
          <Alert
            bsStyle="danger"
            isGlobal
            autoDismiss
            onDismiss={() => {
              this.setState({ error: false });
            }}
          >
            <p data-bdd="login-failed">
              <strong>Error. </strong>
              {error}
            </p>
          </Alert>
        )}
        <div className="login__wrap">
          <div className="login__main">
            <div className="login__logo">
              <div className="login__logo--icon">
                <img
                  alt={`${customer.displayName} logo`}
                  src={`/customers/${customerId}/monarch-logo-glyph.png`}
                />
              </div>
              <div className="login__logo--text">
                <AppName />
              </div>
            </div>
            <Form
              horizontal
              className="login__form"
              disabled={isLoading}
              onSubmit={this.handleSubmit}
            >
              <FormGroup bsSize="lg" controlId="username">
                <ControlLabel>Username</ControlLabel>
                <FormControl
                  bsSize="large"
                  disabled={isLoading}
                  id="username"
                  data-bdd="login-username"
                  inputRef={this.saveRef("username")}
                  name="username"
                  onChange={this.handleInputChange}
                  type="text"
                  value={username}
                />
              </FormGroup>
              <FormGroup bsSize="lg" controlId="password">
                <ControlLabel>Password</ControlLabel>
                <FormControl
                  bsSize="large"
                  disabled={isLoading}
                  id="password"
                  data-bdd="login-password"
                  inputRef={this.saveRef("password")}
                  name="password"
                  onChange={this.handleInputChange}
                  type="password"
                  value={password}
                />
              </FormGroup>
              <div>
                <Button
                  data-bdd="login-button"
                  bsStyle="info"
                  disabled={isLoading}
                  bsSize="large"
                  type="submit"
                >
                  Login
                </Button>

                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </Form>
            {Login.isSafari() && (
              <Alert bsStyle="info" data-bdd="login-safari-alert" hasIcon>
                <p>
                  <strong>We&apos;ve notice you&apos;re using safari.</strong>
                  <br />
                  In order to continue using safari, be sure to uncheck the
                  &apos;Prevent cross-site tracking option&apos; in your
                  preferences.
                  <br />
                  This is in Safari &gt; Preferences &gt; Privacy
                </p>
              </Alert>
            )}
          </div>
        </div>
        {showModal && (
          <ForgotPassword
            config={config}
            history={history}
            setAlert={this.setAlert}
            showModal={showModal}
          />
        )}
      </main>
    );
  }
}
