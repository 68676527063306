import { PARENT_KEYS, TYPE, TYPE_ARRAY } from "./flatten-object";
import { pipe, reduce } from "./functional";

const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const updateSchema = (str, acc) => ({
  ...acc,
  schema: acc.schema + str
});

const convertToSchema = (obj, name = "Policy", level = 0) =>
  pipe.objToArr(obj)(
    reduce(
      (acc, [k, v]) => {
        if (k === PARENT_KEYS) {
          const { arrayParents } = acc;
          const type = `type ${name} {
  id: ID!
${v
            .map(
              key => `  ${key}: ${arrayParents[key] || capitalize(key)}
`
            )
            .join("")}}

`;
          return updateSchema(type, acc);
        }
        if (typeof v !== "string") {
          const typeDef = `type ${capitalize(k)} { ${convertToSchema(
            v,
            level + 1
          )}
}

`;
          return updateSchema(typeDef, acc);
        }
        if (v === TYPE) {
          return updateSchema(
            `
  ${k}: ${capitalize(k)}`,
            acc
          );
          //         return `${acc}
          // ${k}: ${capitalize(k)}`;
        }
        if (v === TYPE_ARRAY) {
          if (obj[PARENT_KEYS] && obj[PARENT_KEYS].includes(k)) {
            return {
              ...acc,
              arrayParents: {
                ...acc.arrayParents,
                [k]: `[${capitalize(k.slice(0, -1))}]`
              }
            };
          }
          return updateSchema(
            `
  ${k}: [${capitalize(k.slice(0, -1))}]`,
            acc
          );
        }
        return updateSchema(
          `
  ${k}: String`,
          acc
        );
      },
      { schema: "", arrayParents: {} }
    ),
    ({ schema }) => schema
  );

export default convertToSchema;
