import { Alert } from "@icg360/ui-toolkit";
import { node, object, string } from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

class ModuleBoundary extends Component {
  static propTypes = {
    children: node.isRequired,
    label: string.isRequired,
    location: object.isRequired,
    config: object.isRequired
  };

  static getDerivedStateFromError(e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      errorMessage: e.message
    };
  }

  state = {
    errorMessage: null
  };

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { errorMessage } = this.state;
    if (errorMessage && location !== prevProps.location) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        errorMessage: null
      });
    }
  }

  render() {
    const {
      children,
      label,
      location,
      config: {
        ipcm: { supportLink }
      }
    } = this.props;
    const { errorMessage } = this.state;
    const [, basepath, ...subpath] = location.pathname.split("/");
    if (errorMessage) {
      return (
        <Alert bsStyle="danger" hasIcon isGlobal>
          <p>
            <strong>An error occurred in {label}.</strong>
            <br />
            If you continue to see this error, please{" "}
            <a href={supportLink}>contact support</a> and provide the error
            message below.
          </p>
          {subpath.length > 0 && (
            <p>
              <Link to={`/${basepath}`}>Reload {label}</Link>
            </p>
          )}
          <hr />
          <p>
            <code>{errorMessage}</code>
          </p>
        </Alert>
      );
    }
    return children;
  }
}

export default ModuleBoundary;
