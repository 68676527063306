import React from "react";

// Used on both login screen and header (when logged in)
const AppName = () => (
  <span>
    <span style={{ opacity: ".75" }}>IPC</span> <strong>Manager</strong>
  </span>
);

export default AppName;
