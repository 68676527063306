import { fetchText } from "../utils/fetch";
import parseIdentity from "../utils/xml/parseIdentity";

export default (config, username) =>
  fetchText(
    [
      config.services.ixdirectory.url,
      config.services.ixdirectory.basePath,
      "identities",
      username
    ].join("/"),
    {
      credentials: "include"
    }
  ).then(parseIdentity);
