import React from "react";
import "./UnknownRoute.css";

const UnknownRoute = () => (
  <div className="unknown-route">
    <h1 className="unknown-route__title">This route was not found.</h1>
  </div>
);

export default UnknownRoute;
