import htmlparser from "htmlparser2";
import PolicyHandler from "./policy-handler";
import flattenObject from "./utils/flatten-object";
import convertToSchema from "./utils/convert-to-schema";
import { pipe } from "./utils/functional";

const extractNotes = xml => {
  let count = 0;
  const notesContent = {};
  const returnXML = xml.replace(
    /<RelatedItems>([^]+?)<\/RelatedItems>/,
    relatedMatch =>
      relatedMatch.replace(/<Notes>([^]+?)<\/Notes>/, notesMatch =>
        notesMatch.replace(/<Content>([^<]*)<\/Content>/g, (m, content) => {
          count += 1;
          notesContent[count] = content;
          return `<ContentId>${count}</ContentId>`;
        })
      )
  );
  return [returnXML, notesContent];
};

const reinjectNotes = (parsed, notesContent) => {
  return parsed.relatedItems && parsed.relatedItems.Notes
    ? {
        ...parsed,
        relatedItems: {
          ...parsed.relatedItems,
          Notes: parsed.relatedItems.Notes.map(({ ContentId, ...note }) => ({
            ...note,
            Content: notesContent[ContentId]
          }))
        }
      }
    : parsed;
};

const policyParser = {
  parse: (xml, { strict = false } = {}) => {
    try {
      // Take notes out before parsing because we need to
      // maintain newline characters
      const [policyXML, notesContent] = extractNotes(xml);
      const handler = new PolicyHandler(strict);
      new htmlparser.Parser(handler, {
        xmlMode: true,
        decodeEntities: true,
        normalizeWhitespace: false
      }).end(policyXML);
      return reinjectNotes(handler.result, notesContent);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      if (e) {
        throw e;
      } else {
        throw new Error("That doesn't appear to be a valid XForms document.");
      }
    }
  },
  generateSchema: xml =>
    pipe(xml)(
      policyParser.parse,
      flattenObject,
      convertToSchema
      // log('schema')
    )
  // extractXPaths: xml => {
  //   new htmlparser.Parser(XpathExtractor, { xmlMode: true }).end(xml);
  //   return XpathExtractor;
  // },
};

export default policyParser;
