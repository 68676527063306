import React from "react";
import { LoadingPage, Pagination, SafeHTML } from "@package/ipcmgr-toolkit";
import PropTypes from "prop-types";
import styled from "styled-components";
import { userPropTypes } from "../utils/user";
import {
  CenterWrapper,
  DateTime,
  Notes,
  Note,
  Title,
  Page,
  PageHeader
} from "./shared/Notifications";
import useNotices from "../hooks/useNotices";

const StyledNote = styled(Note)`
  ul,
  ol {
    list-style-position: inside;
    padding-left: 0;
  }
  position: relative;
`;

const Notices = ({ user: { id: userId }, flags }) => {
  const {
    alreadyViewedNoticeIds,
    notices,
    isLoading,
    from,
    setFrom,
    size,
    total = 0
  } = useNotices({
    shouldMarkAsViewed: true,
    userId,
    flags
  });

  if (isLoading) {
    return <LoadingPage />;
  }

  const isFirstPage = from === 0;

  return (
    <Page>
      <CenterWrapper>
        <PageHeader>Agent Portal Notices</PageHeader>
        <Notes>
          {notices?.map(
            // eslint-disable-next-line react/prop-types
            ({ createdTimestamp, content, id, title }) => {
              return (
                <StyledNote
                  data-bdd="ap-notice"
                  key={createdTimestamp}
                  isNew={isFirstPage && !alreadyViewedNoticeIds.includes(id)}
                >
                  <DateTime date={createdTimestamp} />
                  <Title>{title}</Title>
                  <SafeHTML data-bdd="ap-notice-content" html={content} />
                </StyledNote>
              );
            }
          )}
        </Notes>
        <Pagination
          from={from}
          onFromChange={setFrom}
          size={size}
          totalCount={total}
        />
      </CenterWrapper>
    </Page>
  );
};

Notices.propTypes = {
  flags: PropTypes.object.isRequired,
  user: PropTypes.shape(userPropTypes).isRequired
};

export default Notices;
